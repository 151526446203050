<template>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <c-table
      ref="table"
      title="검진결과 목록"
      tableId="table"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :merge="grid.merge"
      :data="grid.data"
      :filtering="true"
      :columnSetting="false"
      :usePaging="false"
      :isExcelDown="false"
      :isFullScreen="false"
      :expandAll="true"
      rowKey="heaCheckupResultId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검진결과 업로드" icon="upload"  @btnClicked="openExcelUploader"/>
          <c-btn label="전체 삭제" icon="delete_forever" @btnClicked="removeAll" />
        </q-btn-group>
      </template>
      <template slot="table-chip">
        <font color="#C10015">
          ※ 추가로 엑셀업로드 시 기존데이터에 결과가 추가됩니다(중복 허용). 다시 업로드 하고 싶으면 전체 삭제 후 진행하세요.
        </font>
      </template>
    </c-table>
    <c-dialog :param="popupOptions" :isConfirm="true"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'check-up-result',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaCheckupPlanId: '',
      }),
    },
    checkUp: {
      type: Object,
      default: () => ({
        heaCheckupPlanId: '',
        plantCd: '',
        checkupName: '',
        checkupTypeCd: null,
        checkupStartDate: '',
        checkupEndDate: '',
        regUserId: '',
        chgUserId: '',
        uploadFlag: 'N',

        // prototype
        hospitalId: '',
        hospitalLocation: '',
        hospitalName: '',
        typeFlag: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: false,
      grid: {
        merge: [
          { index: 0, colName: 'upDeptCd' },
          { index: 1, colName: 'deptCd' },
        ],
        columns: [
          {
            name: 'upDeptName',
            field: 'upDeptName',
            label: '반',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '팀',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'empNo',
            field: 'empNo',
            label: '사번',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '대상자',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
            type: 'link'
          },
          {
            name: 'checkupDate',
            field: 'checkupDate',
            label: '검진일',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'birthDate',
            field: 'birthDate',
            label: '생년월일',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
        ],
        data: [],
        height: '800px',
      },
      mappingType: 'POST',
      listUrl: '',
      isSave: false, 
      saveUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
        isUpload: false,
        saveUploadUrl: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.hea.checkup.result.list.url;
      this.saveUrl = transactionConfig.hea.checkup.results.save.url;
      this.deleteUrl = transactionConfig.hea.checkup.results.delete.url;
      this.saveUploadUrl = transactionConfig.hea.examine.resultUpload.url;


      this.getDetail();
    },
    getDetail() {
      if (this.checkUp.heaCheckupPlanId) {
        this.$http.url = this.$format(this.listUrl, this.checkUp.heaCheckupPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      } 
    },
    linkClick(result) {
      this.popupOptions.target = () => import(`${"./checkUpResultDetail.vue"}`);
      this.popupOptions.title = result.userName + '의 건강검진 결과 상세';
      this.popupOptions.width = '70%';
      this.popupOptions.isFull = true;
      this.popupOptions.param = {
        heaCheckupResultId: result ? result.heaCheckupResultId : '',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getDetail();
    },
    openExcelUploader() {
      this.deleteTemps();
      this.popupOptions.title = '건강검진결과 업로드'; // 건강검진결과 업로드
      this.popupOptions.param = {
        hospitalId: this.checkUp.hospitalId,
        hospitalName: this.checkUp.hospitalName,
        plantCd: this.checkUp.plantCd,
      }
      this.popupOptions.target = () => import(`${'./checkUpResultExcelUpload.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploadPopup;
    },
    closeExcelUploadPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let saveData = {
          heaCheckupPlanId: this.checkUp.heaCheckupPlanId,
          heaCheckupResultId: uid(),
          regUserId: this.$store.getters.user.userId,
          chgUserId: this.$store.getters.user.userId,
        }
        this.$http.url = this.saveUploadUrl
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getDetail();
          this.deleteTemps();
        },);
      } else {
        this.deleteTemps();
      }
    },
    // 업로드용 Temp Data 일괄 삭제
    deleteTemps() {
      this.$http.url = transactionConfig.hea.examine.tempUploadDelete.url;
      this.$http.type = 'DELETE';
      this.$http.request();
    },
    removeAll() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까? ',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.checkUp.heaCheckupPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.getDetail();
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    }
  }
};
</script>